.custom-checkbox {
    width: 1 rem; /* Increase the size of the checkbox */
    height: 1 rem; /* Increase the size of the checkbox */
    background-color: white; /* Default background color */
    border: 1px solid #A1A1A1; /* Border color */
    border-radius: 0.25rem; /* Border radius */
    appearance: none; /* Remove default appearance */
    cursor: pointer; /* Pointer cursor on hover */
  }
  
  .custom-checkbox:checked {
    background-color: black; /* Background color when checked */
    border: 1px solid black; /* Border color when checked */
  }
  
  .custom-checkbox:checked::before {
    content: "✔"; /* Add checkmark when checked */
    color: white; /* Color of the checkmark */
    display: flex;
    padding: 1px;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 0.75rem; 
    height: 100%;
  }
  