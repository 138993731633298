.custom-radio {
    appearance: none;
    border: 1px solid #ddd; /* Outer circle color */
    border-radius: 50%;
    width: 16px; /* Size of the outer circle */
    height: 16px; /* Size of the outer circle */
    position: relative;
    cursor: pointer;
  }

  .custom-radio:checked {
    border-color: #616161; /* Outer circle color when checked */
  }

  .custom-radio:checked::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px; /* Size of the inner black dot */
    height: 8px; /* Size of the inner black dot */
    border-radius: 50%;
    background-color: black; /* Color of the inner black dot */
  }