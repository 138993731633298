@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  /* font-family: "Manrope", sans-serif; */
  font-family: "Figtree", serif;

  overflow-x: hidden;
}

/* .ant-dropdown,
.ant-dropdown-menu {
  background: transparent !important; 
  box-shadow: none !important;      

} */




/* Custom CSS for sorting icons */
.ant-table-column-sorters {
  color: inherit;
  /* Ensure inherited colors for icons */
}

/* Default sort icon color */
.custom-table .ant-table-column-sorter {
  color: #ebebeb;
  /* Default color */
}

/* Active ascending sort icon color */
.custom-table .ant-table-column-sorter-up .ant-table-column-sorter-icon {
  color: #414141;
  /* Ascending color */
}

/* Active descending sort icon color */
.custom-table .ant-table-column-sorter-down .ant-table-column-sorter-icon {
  color: #414141;
  /* Descending color (default color) */
}



.custom-scrollbar::-webkit-scrollbar {
  display: none;
}


/* Hide spinner buttons for webkit browsers (Chrome, Safari, newer versions of Opera) */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide spinner buttons for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* New scrollbar customization */
.new-scrollbar {
  scrollbar-width: thin;
  /* For Firefox - thin scrollbar */
  scrollbar-color: #414141 !important;
  /* Thumb color and track color for Firefox */
}

.new-scrollbar::-webkit-scrollbar {
  width: 4px;
  /* Width of the scrollbar for WebKit-based browsers */
}

.new-scrollbar::-webkit-scrollbar-thumb {
  background-color: #414141 !important;
  /* Scrollbar thumb (handle) color */
  border-radius: 10px;
  /* Optional: rounded corners for thumb */
}

.new-scrollbar::-webkit-scrollbar-track {
  background: transparent;
  /* Transparent scrollbar track */
}

.new-scrollbar::-webkit-scrollbar-button {
  display: none;
  /* Hides the top and bottom arrows */
}




::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #A1A1A1;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}





.custom-table .ant-table-thead > tr > th::before {
  content: none !important;
}

.custom-table .ant-table-thead > tr > th {
  border: none !important;
}



.custom-table .ant-table-expanded-row .ant-table-cell {
  background-color: #F9F9FB; /* Customize background color */
  border-radius: 8px; /* Customize border radius */
  /* box-shadow: 0px 1px 2px 0px #0000000D; */
  border: 1px solid var(--Gray-Cool-100, #EFF1F5);
  padding:24px;
}


svg path:focus, svg circle:focus, svg g:focus {
  outline: none;
}

/* Remove bottom border when data is empty */
.empty-data .ant-table-cell {
  border-bottom: none !important; /* Remove the bottom border */
}


/* .custom-pagination .ant-pagination-item  {
  border:none;
  background-color: none !important;
  list-style: none;
} */

/* Remove default background color and hover effects */
.custom-pagination .ant-pagination-item {
  background-color: transparent !important; /* Remove background color */
  border: none !important; /* Remove border */
}

.custom-pagination .ant-pagination-item a {
  color: inherit !important; /* Use inherit for text color */
}

.custom-pagination .ant-pagination-item-active {
  background-color: transparent !important; /* Remove active background color */
}

.custom-pagination .ant-pagination-item:hover {
  background-color: transparent !important; /* Remove hover background color */
}

.custom-pagination .ant-pagination-item-active a {
  color: inherit !important; /* Keep the text color consistent */
}



.expanded-row >td {
  border-bottom: none !important;
}

@keyframes pulse {
  0% {
    r: 6;
    opacity: 1;
  }
  50% {
    r: 10;
    opacity: 0.5;
  }
  100% {
    r: 6;
    opacity: 1;
  }
}

.blinking-dot {
  animation: pulse 1s infinite;
}
